@font-face {
  font-family: SVN-Conqueror-Didot;
  src: url("SVN-Conqueror-Didot.4ad4095a.otf") format("truetype");
}

@font-face {
  font-family: Orbitron;
  src: url("Orbitron-Medium.7f39cc7e.ttf") format("truetype");
}

body {
  color: #000;
  background-color: #fff;
  margin: 0;
  font-family: Helvetica, sans-serif;
  overflow-y: hidden;
}

.mobile {
  display: none;
}

.background {
  filter: blur(8px);
  z-index: -1;
  background-image: url("background-wedding.b4ffe253.jpg");
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
}

.container-loading {
  z-index: 3;
  text-align: center;
  background-color: #000;
  align-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
}

.container-loading.done {
  opacity: 0;
  transition: all 1s linear;
  transform: scale(1.2);
}

.container-loading h1 {
  color: #ffffff4d;
  font: 900 90px Orbitron, sans-serif;
}

@keyframes loading {
  from {
    max-width: 0;
  }
}

.container-loading h1:before {
  content: attr(data-content);
  color: #fff;
  max-width: 5em;
  animation: 1.5s linear loading;
  position: absolute;
  overflow: hidden;
}

.container-loading p {
  color: #fff;
  margin: 0;
  font-family: Orbitron, sans-serif;
  font-size: 30px;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.logo {
  color: #fff;
  pointer-events: none;
  font-family: Orbitron, sans-serif;
  font-size: 50px;
  position: absolute;
  top: 20px;
  left: 20px;
}

.burger-button {
  z-index: 2;
  position: fixed;
  top: 50px;
  right: 50px;
}

.hamburger .line {
  -o-transition: all .3s ease-in-out;
  background-color: #fff;
  border-radius: 10px;
  width: 50px;
  height: 5px;
  margin: 8px auto;
  transition: all .3s ease-in-out;
  display: block;
}

.hamburger:hover {
  cursor: pointer;
}

.hamburger.is-active .line {
  background-color: #000;
}

#hamburger-1.is-active .line:nth-child(2) {
  opacity: 0;
}

#hamburger-1.is-active .line:first-child {
  transform: translateY(13px)rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3) {
  transform: translateY(-13px)rotate(-45deg);
}

.overlay-contact-us-panel {
  z-index: 1;
  visibility: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.overlay-contact-us-panel.active {
  visibility: visible;
}

.contact-us-panel {
  z-index: 2;
  text-align: right;
  background-color: #fff;
  width: 250px;
  height: 100%;
  transition: all .3s linear;
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(100%);
}

.overlay-contact-us-panel.active .contact-us-panel {
  transition: all .3s linear;
  transform: translateX(0);
}

.contact-us-panel h1 {
  margin-top: 130px;
  padding-right: 50px;
}

.contact-us-panel .link {
  padding: 10px 50px 10px 0;
}

.contact-us-panel .link a {
  color: #000;
  font-size: 20px;
  text-decoration: none;
}

.content-top {
  width: 80%;
  height: 50%;
  margin: 150px auto;
  display: flex;
}

.content-top .left {
  width: 50%;
  position: relative;
}

.content-top .left img {
  filter: blur();
  visibility: hidden;
  width: 30vw;
  transition: all 1s;
  position: absolute;
}

.content-top .left .service-image.blur {
  filter: blur(200px);
  transition: all 1s;
}

.content-top .left img.selected {
  visibility: visible;
}

.content-top .right {
  background: #ffffffb3;
  border-radius: 20px;
  width: 50%;
  height: 45vh;
  margin: 0 0 0 100px;
  padding: 20px 50px;
  position: relative;
}

.content-top .right .service-content {
  visibility: hidden;
  position: absolute;
}

.content-top .right .service-content.selected {
  visibility: visible;
  animation: 1s show-content;
}

@keyframes show-content {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.content-top .right .service-content h1 {
  font-family: SVN-Conqueror-Didot, sans-serif;
  font-size: 50px;
}

.content-top .right .service-content p {
  padding-right: 50px;
  font-family: Helvetica, sans-serif;
  font-size: 20px;
}

.content-top .right .button-information {
  position: absolute;
  bottom: 15px;
  right: 20px;
}

.content-bottom {
  width: 90%;
  height: 150px;
  margin-left: 5%;
  display: flex;
  position: fixed;
  bottom: 0;
}

.content-bottom .child {
  text-align: center;
  opacity: .9;
  background-color: #ffffffb3;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  flex-grow: 1;
  flex-basis: 0;
  margin-top: 50px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 20px 5px 0;
  transition: margin-top .5s;
}

.content-bottom .child:hover {
  box-shadow: 0 0 10px #fddbc0, 0 0 10px #fddbc0, 0 0 10px #fddbc0, 0 0 10px #fddbc0;
}

.content-bottom .child.selected {
  margin-top: 0;
  transition: margin-top .5s;
  box-shadow: 0 0 10px #fddbc0, 0 0 10px #fddbc0, 0 0 10px #fddbc0, 0 0 10px #fddbc0;
}

.content-bottom .child div {
  pointer-events: none;
  color: #000;
  margin: 0;
  font-family: SVN-Conqueror-Didot, sans-serif;
  font-size: 25px;
}

.overlay {
  visibility: hidden;
  opacity: 0;
  z-index: 3;
  align-content: center;
  position: fixed;
  inset: 0;
}

.overlay.show {
  visibility: visible;
  opacity: 1;
}

.overlay .hide-overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
}

.popup {
  backdrop-filter: blur(50px);
  opacity: 0;
  background: #ffffff4d;
  border-radius: 20px;
  width: 50%;
  height: 600px;
  margin: 70px auto;
  transition: all .5s linear;
  position: relative;
  box-shadow: 0 8px 25px #0003;
}

.popup.show {
  opacity: 1;
  transition: all .5s linear;
}

.popup .popup-content {
  margin: 40px 40px 0;
  display: none;
  position: absolute;
}

.popup .popup-content.selected {
  display: block;
}

.popup .popup-content .content {
  max-height: 470px;
  overflow-y: auto;
}

.popup .popup-content h2 {
  color: #000;
  margin-top: 0;
  margin-bottom: 20px;
  font-family: SVN-Conqueror-Didot, sans-serif;
  font-size: 50px;
}

.popup .popup-content h3 {
  color: #000;
  margin-top: 0;
  margin-bottom: 20px;
  font-family: SVN-Conqueror-Didot, sans-serif;
  font-size: 30px;
}

.popup .popup-content p {
  color: #000;
  margin-top: 0;
  padding-right: 10px;
  font-family: Helvetica, sans-serif;
  font-size: 20px;
}

.popup .close {
  color: #000;
  z-index: 2;
  font-size: 50px;
  font-weight: bold;
  text-decoration: none;
  position: absolute;
  top: 20px;
  right: 30px;
}

.popup .close:hover {
  color: #06d85f;
}

.popup .close div {
  pointer-events: none;
  font-size: 50px;
}

.popup .content-image {
  grid-column-gap: 0;
  grid-row-gap: 0;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  width: 100%;
  margin-top: 25px;
  display: grid;
}

.popup .content-image img {
  border-radius: 30px;
  width: 17vw;
}

@media (width <= 1600px) {
  .burger-button {
    top: 40px;
    right: 40px;
  }

  .content-top {
    margin: 140px auto;
  }

  .content-top .right .service-content h1 {
    font-size: 45px;
  }

  .content-top .right .service-content p {
    font-size: 17px;
  }

  .content-bottom {
    height: 140px;
  }

  .content-bottom .child div {
    font-size: 22px;
  }

  .popup {
    height: 500px;
  }

  .popup .popup-content .content {
    max-height: 380px;
  }

  .popup .popup-content h2 {
    font-size: 45px;
  }

  .popup .popup-content h3 {
    font-size: 25px;
  }

  .popup .popup-content p {
    font-size: 17px;
  }
}

@media (width <= 1200px) {
  .burger-button {
    top: 30px;
    right: 30px;
  }

  .content-top {
    margin: 130px auto;
  }

  .content-top .right .service-content h1 {
    font-size: 40px;
  }

  .content-top .right .service-content p {
    font-size: 15px;
  }

  .content-bottom {
    height: 130px;
  }

  .content-bottom .child div {
    font-size: 20px;
  }

  .popup {
    height: 400px;
  }

  .popup .popup-content .content {
    max-height: 280px;
  }

  .popup .popup-content h2 {
    font-size: 40px;
  }

  .popup .popup-content h3 {
    font-size: 20px;
  }

  .popup .popup-content p {
    font-size: 15px;
  }

  @media (orientation: portrait) {
    .content-top {
      display: block;
    }

    .content-top .left {
      text-align: center;
      width: 100%;
      height: 30vh;
    }

    .content-top .left img {
      justify-self: center;
      top: 0;
      left: 0;
      right: 0;
    }

    .content-top .right {
      justify-self: center;
      width: 70%;
      height: 30vh;
      margin: 0;
    }

    .popup {
      width: 70%;
      height: 600px;
    }

    .popup .popup-content .content {
      max-height: 480px;
    }

    .popup .content-image img {
      width: 25vw;
    }
  }
}

@media (width <= 767px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .container-loading h1 {
    font-size: 60px;
  }

  .container-loading h1:before {
    max-width: 5.5em;
  }

  .container-loading p {
    font-size: 25px;
  }

  .logo {
    font-size: 35px;
  }

  .burger-button {
    top: auto;
    bottom: 20px;
    right: 20px;
  }

  .hamburger .line {
    width: 35px;
    height: 3px;
  }

  #hamburger-1.is-active .line:first-child {
    transform: translateY(11px)rotate(45deg);
  }

  #hamburger-1.is-active .line:nth-child(3) {
    transform: translateY(-11px)rotate(-45deg);
  }

  .contact-us-panel {
    border-radius: 20px 0 0;
    width: 210px;
    height: 65%;
    top: auto;
    bottom: 0;
    right: 0;
  }

  .contact-us-panel h1 {
    margin-top: 35px;
    padding-right: 20px;
  }

  .contact-us-panel .link {
    padding: 8px 20px 8px 0;
  }

  .background {
    filter: blur(30px);
    background-position: bottom;
    background-size: cover;
  }

  .menu-service {
    grid-column-gap: 0;
    grid-row-gap: 0;
    grid-template-rows: repeat(4, 1fr);
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    max-height: 75vh;
    margin-top: 120px;
    display: grid;
    overflow: hidden auto;
  }

  .menu-service .item {
    text-align: center;
  }

  .menu-service .item img {
    border-radius: 20px;
    width: 140px;
    box-shadow: 0 8px 25px #0003;
  }

  .menu-service .item p {
    margin: 10px 0;
    font-family: SVN-Conqueror-Didot, sans-serif;
    font-size: 4vw;
    font-weight: bold;
  }
}
/*# sourceMappingURL=index.af907d10.css.map */
