@font-face {
    font-family: "SVN-Conqueror-Didot";
    src: url("../../../assets/fonts/SVN-Conqueror-Didot.otf") format("truetype");
}

@font-face {
    font-family: "Orbitron";
    src: url("../../../assets/fonts/Orbitron-Medium.ttf") format("truetype");
}

body {
    margin: 0;
    background-color: white;
    color: black;
    overflow-y: hidden;
    font-family: Helvetica, sans-serif;
}

.mobile {
    display: none;
}

.background {
    position: fixed;
    background-image: url(../../../assets/images/wedding/background-wedding.jpg);
    background-size: cover;
    filter: blur(8px);
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
}

.container-loading {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: black;
    align-content: center;
    text-align: center;
    top: 0;
}

.container-loading.done {
    opacity: 0;
    transform: scale(120%);
    transition: all 1s linear;
}

.container-loading h1 {
    color: hsla(0, 0%, 100%, .3);
    font: 900 800% Orbitron, sans-serif;
    font-size: 90px;
}

@keyframes loading {
    from {
        max-width: 0;
    }
}

.container-loading h1:before {
    content: attr(data-content);
    position: absolute;
    overflow: hidden;
    max-width: 5em;
    color: white;
    animation: loading 1.5s linear;
}

.container-loading p {
    color: white;
    font-family: Orbitron, sans-serif;
    font-size: 30px;
    position: absolute;
    bottom: 30px;
    margin: 0;
    left: 50%;
    transform: translateX(-50%);
}

.logo {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 50px;
    font-family: 'Orbitron', sans-serif;
    color: white;
    pointer-events: none;
}

.burger-button {
    position: fixed;
    top: 50px;
    right: 50px;
    z-index: 2;
}

.hamburger .line {
    width: 50px;
    height: 5px;
    background-color: #ffffff;
    display: block;
    margin: 8px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border-radius: 10px;
}

.hamburger:hover {
    cursor: pointer;
}

.hamburger.is-active .line {
    background-color: black;
}

#hamburger-1.is-active .line:nth-child(2) {
    opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1) {
    -webkit-transform: translateY(13px) rotate(45deg);
    -ms-transform: translateY(13px) rotate(45deg);
    -o-transform: translateY(13px) rotate(45deg);
    transform: translateY(13px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3) {
    -webkit-transform: translateY(-13px) rotate(-45deg);
    -ms-transform: translateY(-13px) rotate(-45deg);
    -o-transform: translateY(-13px) rotate(-45deg);
    transform: translateY(-13px) rotate(-45deg);
}

.overlay-contact-us-panel {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    visibility: hidden;
}

.overlay-contact-us-panel.active {
    visibility: visible;
}

.contact-us-panel {
    position: fixed;
    width: 250px;
    height: 100%;
    background-color: white;
    right: 0;
    top: 0;
    transform: translateX(100%);
    z-index: 2;
    transition: all 0.3s linear;
    text-align: right;
}

.overlay-contact-us-panel.active .contact-us-panel {
    transform: translateX(0);
    transition: all 0.3s linear;
}

.contact-us-panel h1 {
    margin-top: 130px;
    padding-right: 50px;
}

.contact-us-panel .link {
    padding: 10px 50px 10px 0;
}

.contact-us-panel .link a {
    text-decoration: none;
    color: black;
    font-size: 20px;
}

.content-top {
    width: 80%;
    height: 50%;
    display: flex;
    margin: 150px auto;
}

.content-top .left {
    width: 50%;
    position: relative;
}

.content-top .left img {
    filter: blur(0px);
    transition: all 1s;
    width: 30vw;
    position: absolute;
    visibility: hidden;
}

.content-top .left .service-image.blur {
    filter: blur(200px);
    transition: all 1s;
}

.content-top .left img.selected {
    visibility: visible;
}

.content-top .right {
    width: 50%;
    height: 45vh;
    background: rgba(255, 255, 255, 0.7);
    margin: 0 0 0 100px;
    border-radius: 20px;
    padding: 20px 50px 20px 50px;
    position: relative;
}

.content-top .right .service-content {
    visibility: hidden;
    position: absolute;
}

.content-top .right .service-content.selected {
    visibility: visible;
    animation: show-content 1s;
}

@keyframes show-content {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.content-top .right .service-content h1 {
    font-family: SVN-Conqueror-Didot, sans-serif;
    font-size: 50px;
}

.content-top .right .service-content p {
    /*padding-left: 20px;*/
    padding-right: 50px;
    font-family: Helvetica, sans-serif;
    font-size: 20px;
}

.content-top .right .button-information {
    position: absolute;
    bottom: 15px;
    right: 20px;
}

.content-bottom {
    width: 90%;
    position: fixed;
    bottom: 0;
    height: 150px;
    display: flex;
    margin-left: 5%;
}

.content-bottom .child {
    flex-grow: 1;
    flex-basis: 0;
    margin-right: 10px;
    margin-left: 10px;
    padding: 20px 5px 0 5px;
    background-color: rgba(255, 255, 255, 0.7);
    text-align: center;
    opacity: 0.9;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    margin-top: 50px;
    transition: margin-top 500ms;
}

.content-bottom .child:hover {
    box-shadow: 0 0 10px #fddbc0,
    0 0 10px #fddbc0,
    0 0 10px #fddbc0,
    0 0 10px #fddbc0;
}

.content-bottom .child.selected {
    box-shadow: 0 0 10px #fddbc0,
    0 0 10px #fddbc0,
    0 0 10px #fddbc0,
    0 0 10px #fddbc0;
    margin-top: 0;
    transition: margin-top 500ms;
}

.content-bottom .child div {
    pointer-events: none;
    margin: 0;
    color: black;
    font-family: SVN-Conqueror-Didot, sans-serif;
    font-size: 25px;
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
    opacity: 0;
    align-content: center;
    z-index: 3;
}

.overlay.show {
    visibility: visible;
    opacity: 1;
}

.overlay .hide-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
}

.popup {
    margin: 70px auto;
    /*padding: 20px;*/
    width: 50%;
    height: 600px;
    position: relative;
    transition: all 500ms linear;
    backdrop-filter: blur(50px);
    border-radius: 20px;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
    background: rgba(255, 255, 255, 0.3);
    opacity: 0;
}

.popup.show {
    opacity: 1;
    transition: all 500ms linear;
}

.popup .popup-content {
    margin: 40px 40px 0 40px;
    display: none;
    position: absolute;
}

.popup .popup-content.selected {
    display: block;
}

.popup .popup-content .content {
    overflow-y: auto;
    max-height: 470px;
}

.popup .popup-content h2 {
    margin-top: 0;
    color: black;
    font-family: SVN-Conqueror-Didot, sans-serif;
    font-size: 50px;
    margin-bottom: 20px;
}

.popup .popup-content h3 {
    margin-top: 0;
    color: black;
    font-family: SVN-Conqueror-Didot, sans-serif;
    font-size: 30px;
    margin-bottom: 20px;
}

.popup .popup-content p {
    margin-top: 0;
    color: black;
    font-family: Helvetica, sans-serif;
    font-size: 20px;
    padding-right: 10px;
}

.popup .close {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 50px;
    font-weight: bold;
    text-decoration: none;
    color: #000000;
    z-index: 2;
}

.popup .close:hover {
    color: #06D85F;
}

.popup .close div {
    pointer-events: none;
    font-size: 50px;
}

.popup .content-image {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /*grid-template-rows: repeat(4, 1fr);*/
    grid-column-gap: 0;
    grid-row-gap: 0;
    width: 100%;
    justify-items: center;
    margin-top: 25px;
}

.popup .content-image img {
    width: 17vw;
    border-radius: 30px;
}

@media all and (max-width: 1600px) {
    .burger-button {
        top: 40px;
        right: 40px;
    }

    .content-top {
        margin: 140px auto;
    }

    .content-top .left img {
        /*width: 400px;*/
    }

    .content-top .right {
        /*height: 350px;*/
    }

    .content-top .right .service-content h1 {
        font-size: 45px;
    }

    .content-top .right .service-content p {
        font-size: 17px;
    }

    .content-bottom {
        height: 140px;
    }

    .content-bottom .child div {
        font-size: 22px;
    }

    .popup {
        height: 500px;
    }

    .popup .popup-content .content {
        max-height: 380px;
    }

    .popup .popup-content h2 {
        font-size: 45px;
    }

    .popup .popup-content h3 {
        font-size: 25px;
    }

    .popup .popup-content p {
        font-size: 17px;
    }

    .popup .content-image img {
        /*width: 250px;*/
    }
}

@media all and (max-width: 1200px) {
    .burger-button {
        top: 30px;
        right: 30px;
    }

    .content-top {
        margin: 130px auto;
    }

    .content-top .left img {
        /*width: 350px;*/
    }

    .content-top .right {
        /*height: 300px;*/
    }

    .content-top .right .service-content h1 {
        font-size: 40px;
    }

    .content-top .right .service-content p {
        font-size: 15px;
    }

    .content-bottom {
        height: 130px;
    }

    .content-bottom .child div {
        font-size: 20px;
    }

    .popup {
        height: 400px;
    }

    .popup .popup-content .content {
        max-height: 280px;
    }

    .popup .popup-content h2 {
        font-size: 40px;
    }

    .popup .popup-content h3 {
        font-size: 20px;
    }

    .popup .popup-content p {
        font-size: 15px;
    }

    .popup .content-image img {
        /*width: 180px;*/
    }

    @media (orientation: portrait) {
        .content-top {
            display: block;
        }

        .content-top .left {
            text-align: center;
            width: 100%;
            height: 30vh;
        }

        .content-top .left img {
            top: 0;
            left: 0;
            right: 0;
            justify-self: center;
        }

        .content-top .right {
            margin: 0;
            justify-self: center;
            width: 70%;
            height: 30vh;
        }

        .popup {
            width: 70%;
            height: 600px;
        }

        .popup .popup-content .content {
            max-height: 480px;
        }

        .popup .content-image img {
            width: 25vw;
        }
    }
}

@media all and (max-width: 767px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .container-loading h1 {
        font-size: 60px;
    }

    .container-loading h1:before {
        max-width: 5.5em;
    }

    .container-loading p {
        font-size: 25px;
    }

    .logo {
        font-size: 35px;
    }

    .burger-button {
        top: auto;
        bottom: 20px;
        right: 20px;
    }

    .hamburger .line {
        width: 35px;
        height: 3px;
    }

    #hamburger-1.is-active .line:nth-child(1) {
        -webkit-transform: translateY(11px) rotate(45deg);
        -ms-transform: translateY(11px) rotate(45deg);
        -o-transform: translateY(11px) rotate(45deg);
        transform: translateY(11px) rotate(45deg);
    }

    #hamburger-1.is-active .line:nth-child(3) {
        -webkit-transform: translateY(-11px) rotate(-45deg);
        -ms-transform: translateY(-11px) rotate(-45deg);
        -o-transform: translateY(-11px) rotate(-45deg);
        transform: translateY(-11px) rotate(-45deg);
    }

    .contact-us-panel {
        width: 210px;
        height: 65%;
        right: 0;
        top: auto;
        bottom: 0;
        border-radius: 20px 0 0 0;
    }

    .contact-us-panel h1 {
        margin-top: 35px;
        padding-right: 20px;
    }

    .contact-us-panel .link {
        padding: 8px 20px 8px 0;
    }

    .background {
        filter: blur(30px);
        background-position: bottom;
        background-size: cover;
    }

    .menu-service {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-column-gap: 0;
        grid-row-gap: 0;
        width: 100%;
        /*height: 100%;*/
        max-height: 75vh;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 120px;
    }

    .menu-service .item {
        text-align: center;
    }

    .menu-service .item img {
        width: 140px;
        border-radius: 20px;
        box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
    }

    .menu-service .item p {
        font-family: SVN-Conqueror-Didot, sans-serif;
        font-size: 4vw;
        margin: 10px 0 10px 0;
        font-weight: bold;
    }
}